let wrapper = document.querySelector('.contact-user-widget');
if (wrapper) {
  let phone = wrapper.querySelector('.contact-phone');
  let url = wrapper.querySelector('.contact-url');
  let messageField = wrapper.querySelector('.contact-message');
  let url_template = url.dataset.url;

  let updateUrl = (phone, message)=> {
    url.href = url_template
      .replace('{PHONE}', phone)
      .replace('{MESSAGE}', message);
  }

  phone.addEventListener('input', (e)=> {
    updateUrl(e.target.value, messageField.value);
  })

  messageField.addEventListener('input', (e)=> {
    updateUrl(phone.value, e.target.value);
  })
}
