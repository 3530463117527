import CableReady from 'cable_ready'
import consumer from './consumer'

consumer.subscriptions.create('DraftChannel', {
  received (data) {
    if (data.cableReady) {
      console.log('operations', data.operations)
      CableReady.perform(data.operations)
    }
  }
})
