import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "translatedText", "button", "loading",
    "chooseLanguageForm", 'submitButton', "toLang"
  ];
  static values = {
    pid: String,
  }

  // initialize() {
  //   console.log('Dropdown -> initialized')
  // }

  connect() {
    console.log('Dropdown -> Connected')
    this.url = "/translations?pid=PID&to=LANG";
    this.subscribed = window.subscribed || false;
  }

  chooseLanguage(ev) {
    ev.preventDefault();

    // this.buttonTarget.disabled = true;
    // this.buttonTarget.classList.add('disabled');

    if (!this.subscribed) {
      this.show_message(this.subscribe_message);
      return;
    }

    this.chooseLanguageFormTarget.classList.toggle('d-none');
  }

  translate(ev) {

    this.submitButtonTarget.disabled = true;
    this.submitButtonTarget.classList.add('disabled');

    this.loadingTarget.classList.remove('d-none');

    console.log('URL', this.urlWithParams);
    // console.log('URL', this.toLangTarget.value);

    // return;

    const options = {
      method: 'POST',
      credentials: 'same-origin',
      body: null,
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "X-CSRF-Token": this.csrf_token,
      }
    }

    fetch(this.urlWithParams, options)
      .then(response => response.json())
      .then(data => {
        console.log('TRANSLATION DATA', data);

        if (data.ok) {
          this.generateTranslation(data.text);
          this.buttonTarget.classList.add('d-none');
          this.chooseLanguageFormTarget.classList.toggle('d-none');

        } else {
          console.log('FAILED', data.message);
          // this.buttonTarget.disabled = false;
          this.show_message(data.message);
        }

        this.loadingTarget.classList.add('d-none');
      });
  }

  generateTranslation(text) {
    let node = this.generateNode(text);
    this.translatedTextTarget.insertAdjacentHTML('afterbegin', node);
  }

  generateNode(text) {
    const node = document.createElement('p');
    node.classList.add('text-right');
    node.innerHTML = text
    return node.outerHTML;
  }

  show_message(text) {
    const node = document.createElement('div');
    node.innerHTML = text;
    node.classList.add('alert', 'alert-danger');
    this.translatedTextTarget.insertAdjacentHTML('afterbegin', node.outerHTML);
  }

  get subscribe_message() {
    return `
      <p class="text-xl">Please subscribe to a translation plan to activate this feature.</p>
      <a href="/plans#translation" class="btn btn-light mt-3">Subscribe</a>
    `
  }

  get urlWithParams() {
    return this.url
      .replace('PID', this.pidValue)
      .replace('LANG', this.toLangTarget.value);
  }

  get csrf_token() {
    return document.querySelector("meta[name='csrf-token']").content;
  }
}
