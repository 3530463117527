import { Controller } from "@hotwired/stimulus";
import 'stylesheets/datetimerange.css'
import { DateRangePicker } from 'javascripts/libraries/vanilla-daterange-picker@3-1'

export default class extends Controller {

  connect() {
    this.bind_element();
    this.bind_events();
  }

  bind_element() {
    new DateRangePicker(this.element, {
      autoUpdateInput: false,

      locale: {
        format: this.date_format
      }
    })
  }

  bind_events() {
    const format = this.date_format;
    const el = this.element;

    document.addEventListener('apply.daterangepicker', function(ev) {
      el.value = ev.detail.startDate.format(format) + ' - ' + ev.detail.endDate.format(format)
    });

    document.addEventListener('cancel.daterangepicker', function(ev) {
      el.value = '';
    });
  }

  get date_format() {
    return 'DD/MM/YYYY'
  }
}
