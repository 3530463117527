import Vue from 'vue';


let treeData = [
    { name: "", placeholder: "Main topic 1",
      children: [{ name: "", placeholder: "Sub topic 1"}],
      _destroy: false },
    { name: "", placeholder: "Main topic 2", _destroy: false },
    // {
    //   name: "Title 3", _destroy: false,
    //   children: [
    //     {
    //       name: "Sub title 1",
    //       children: [{ name: "Sub Sub title 1" }, { name: "Sub Sub title 2" }],
    //       _destroy: false
    //     },
    //     { name: "Sub title 2", _destroy: false },
    //     { name: "Sub title 3", _destroy: false },
    //     {
    //       name: "Sub title 4",
    //       children: [{ name: "Sub Sub title 1" }, { name: "Sub Sub title 2" }],
    //       _destroy: false
    //     }
    //   ]
    // }
  ];


let treeItem = {
  name: 'tree-item',
  template: "#tree-item-template",
  props: {
    parent: Object,
    item: Object,
    index: Number,
    make_folder: Boolean,
  },
  data: function() {
    return {
      isOpen: true,
    };
  },
  created: function() {
    console.log('TreeItem created!')
  },
  computed: {
    isFolder: function() {
      return this.item.children && this.item.children.length;
    }
  },
  methods: {
    toggle: function() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },
    makeFolder: function() {
      if (!this.isFolder) {
        this.$emit("make-folder", this.item);
        this.isOpen = true;
      }
    },
    removeItem: function() {
      this.$emit("remove-item", this.item);
    },
    addTitleAfter: function () {
      this.$emit("add-title-after", this.index);
    }
  }
};

let research = new Vue({
  data: function () {
    return {
      treeData: []
    }
  },
  components: {
    'tree-item': treeItem
  },
  created: function() {
    console.log('Research created!')
  },
  mounted: function() {
    this.treeData = window.treeData;
    if (this.treeData.length === 0) { this.treeData = treeData }
  },
  // events: {},
  computed: {
    treeDataAsString: function () {
      return JSON.stringify(this.treeData)
    }
  },
  methods: {
    makeFolder: function(item) {
      Vue.set(item, "children", []);
      this.addItem({parent: item});
    },
    addItem: function({parent, after}) {
      let child = {name: ""};

      if (after !== null) {
        parent.children.splice(after + 1, 0, child);
      } else {
        parent.children.push(child);
      }
    },
    removeItem: function(item) {
      Vue.set(item, "_destroy", true);
    },

    addTitle: function() {
      this.treeData.push({
        name: "",
        _destroy: false
      });
    },

    addTitleAfter: function(after) {
      let title = { name: "", _destroy: false };
      this.treeData.splice(after + 1, 0, title);
    }
  }
})




document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.research-form-wrapper'))
    research.$mount('.research-form-wrapper')
});
