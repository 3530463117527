import CableReady from 'cable_ready'
import consumer from './consumer'

consumer.subscriptions.create({
  channel: 'ResearchChannel',
  id: document.querySelector('meta[name=rid]')?.content
}, {
  connected () {
    console.log('Research channel connected')
  },

  received (data) {
    if (data.cableReady) {
      console.log('operations', data.operations)
      CableReady.perform(data.operations)
    }
  }
})
