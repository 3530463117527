import Vue from 'vue';
import debounce from "debounce";
import TomSelect from "tom-select";

let app = new Vue({
  data: function () {
    return {
      plan_id: null,
      subscription_amount: window.subscription_amount || 0,
      discount_percent: 0,
      coupon_code: null,
      coupon_code_valid: false,
      discount_input: null,
      error_message_el: null,
      success_message_el: null,
      validate_url: null,
      // request_params: {}
    }
  },
  created: function () {
    console.log('Subscription form view created!')
  },
  mounted: function () {
    // this.apply_dropdown();
    this.error_message_el = document.querySelector('.coupon-error');
    this.success_message_el = document.querySelector('.coupon-success');
    this.discount_input = document.querySelector('#coupon_code');
    this.plan_id = this.$el.dataset.planId;
    this.subscription_amount = parseFloat(this.$el.dataset.subscriptionAmount);
    this.validate_url = this.$el.dataset.couponValidateUrl;
    console.log('ele', this.$el.dataset)
  },
  // events: {},
  computed: {
    total: function() {
      return (this.subscription_amount - this.discount_amount)
    },

    discount_amount: function() {
      if (this.discount_percent === 0) { return 0 }

      return (this.subscription_amount * this.discount_percent / 100.0)
    },

    request_params: function() {
      return new URLSearchParams({code: this.coupon_code, plan_id: this.plan_id});
    }
  },
  methods: {
    fetch_discount: debounce(function() {

      if (this.coupon_code.length < 3) {
        this.mark_input_as_default();
        this.hide_error_message();
        return;
      }

      fetch(this.validate_url + '?' + this.request_params)
        .then(response => response.json())
        .then(data => {
          console.log('DATA', data)

          if (data.valid) {
            this.discount_percent = data.discount_percent
            this.mark_input_as_valid();
            this.show_success_message();
            this.hide_error_message();
          } else {
            this.discount_percent = 0;
            this.mark_input_as_invalid();
            this.hide_success_message();
            this.show_error_message();
          }
        });
    }, 400),

    apply_dropdown: function () {
      document.querySelectorAll('[data-behavior="vue-dropdown"]').forEach((item)=> {
        new TomSelect(item, {
          create: false,
          allowEmptyOption: false //item.dataset.blank || true
        })
      })
    },

    mark_input_as_valid: function() {
      this.discount_input.classList.remove('border-danger');
      this.discount_input.classList.add('border-success');
    },

    mark_input_as_invalid: function() {
      this.discount_input.classList.remove('border-success');
      this.discount_input.classList.add('border-danger');
    },

    mark_input_as_default: function () {
      this.discount_input.classList.remove('border-success', 'border-danger');
    },

    show_error_message: function() {
      this.error_message_el.classList.remove('d-none');
    },

    hide_error_message: function() {
      this.error_message_el.classList.add('d-none');
    },

    show_success_message: function() {
      this.success_message_el.classList.remove('d-none');
    },

    hide_success_message: function() {
      this.success_message_el.classList.add('d-none');
    }
  }
});


document.addEventListener('DOMContentLoaded', () => {
  let el = document.querySelector('.subscription-form-wrapper');
  if (el) app.$mount(el)
});
