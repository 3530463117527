document.addEventListener('DOMContentLoaded', () => {
  let contactBtn = document.querySelector('.contact-btn');

  if (!contactBtn) { return; }

  contactBtn.addEventListener('click', (e)=> e.preventDefault());

  window.addEventListener("chatwoot:ready", ()=> {
    contactBtn.addEventListener('click', openChat);
  })
});

let openChat = ()=> {
  window.$chatwoot.toggle("open");
}
