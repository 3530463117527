import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "sourceDropdown", "targetDropdown" ];

  // initialize() {
  //   console.log('Dropdown -> initialized')
  // }

  connect() {
    console.log('Dropdown -> Connected')
    this.url = this.data.get('url');
  }

  handleSelectChange() {
    if (this.sourceValue === '' || this.sourceValue === null) {
      this.clearTargetDropdown();
      return;
    }

    this.populateSelect(this.sourceValue);
  }

  populateSelect(sourceId, targetId = null) {
    let url = this.url.replace('ID', sourceId);

    fetch(url, {
      credentials: 'same-origin'
    })
      .then(response => response.json())
      .then(data => {
        this.generateTargetSelect(data, targetId);
      });
  }

  generateTargetSelect(data, selectedId = null) {
    this.clearTargetDropdown();

    let nodes = this.generateTargetNodes(data, selectedId);
    this.targetDropdownTarget.insertAdjacentHTML('afterbegin', nodes);
  }

  generateTargetNodes(data, selectedId = null) {
    return data.map(item => {
      const opt = document.createElement('option');
      opt.value = item.id;
      opt.innerHTML = item[this.targetDisplayAttribute];
      opt.selected = parseInt(selectedId) === item.id;
      return opt.outerHTML;
    });
  }

  clearTargetDropdown() {
    this.targetDropdownTarget.innerHTML = '';
  }

  get sourceValue() {
    return this.sourceDropdownTarget.value;
  }

  get targetDisplayAttribute() {
    return this.data.get('displayAttribute');
  }
}
