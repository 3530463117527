
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';

import Cookies from 'js-cookie';



let input = document.querySelector("[data-behavior='tel']");
let phone_prefix_el = document.querySelector('#user_phone_prefix')

if (input) {
  let iti = intlTelInput(input, {
    initialCountry: "auto",
    // inject a hidden input with this name, and on submit, populate it with the result of getNumber
    // hiddenInput: "",
    geoIpLookup: function(success, failure) {
      let countryCode = Cookies.get('country-code');

      if (countryCode) {
        success(countryCode);

      } else {
        countryCode = 'eg'; // default

        $.get("https://ipinfo.io", function() {}, "jsonp").always((resp)=> {
          console.log(resp)

          if (resp && resp.country) {
            countryCode = resp.country;
            Cookies.set('country-code', resp.country);
          }

          success(countryCode);
        });
      }
    },

    separateDialCode: true,

    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.16/js/utils.js"
  });

  let handleChange = function() {
    // let text = (iti.isValidNumber()) ? "International: " + iti.getNumber() : "Please enter a number below";
    // console.log(text);

    if (iti.isValidNumber()) {
      input.classList.add('is-valid');
      input.classList.remove('is-invalid');
    } else {
      input.classList.add('is-invalid');
      input.classList.remove('is-valid');
    }
  };

  input.addEventListener('change', handleChange);
  input.addEventListener('keyup', handleChange);

  input.addEventListener("countrychange", function() {
    phone_prefix_el.value = iti.getSelectedCountryData().dialCode
  });

}
